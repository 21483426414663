import ProductAddRequestModel from '@/models/requests/productadd.request'
import authAxios from '@/utils/authaxios.util'
import ServiceConfig from '@/config/services.config'
import ServiceHandler from '@/utils/servicehandler.util'
import CartResponse from '@/models/responses/cart.response'
import appconfigService from '../appconfig/appconfig.service'
import { CartConfigInfo } from '@/store/modules/cart.module'
import PlaceInputRequest from '@/models/requests/place-input.request'
import { PlaceResponse } from '@/models/responses/place.response'
import { Nullable } from '@/utils/customtypes.util'
import store from '@/store'

class CartService {
  public async addProduct (product: ProductAddRequestModel): Promise<void> {
    try {
      const appConfig = await appconfigService.get()
      const serviceUrl = appConfig.getServiceUrl(ServiceConfig.CART_ADD_PRODUCT)

      await authAxios
        .post(serviceUrl, {
          Data: {
            Product: product
          }
        })
        .then(ServiceHandler.checkSuccess<CartResponse>(CartResponse))
        .catch(ServiceHandler.catchError())

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }

  public async updateProduct (
    orderProductId: number,
    product: ProductAddRequestModel
  ): Promise<void> {
    try {
      await this.removeProduct(orderProductId).then(() =>
        this.addProduct(product)
      )

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }

  public async removeProduct (orderProductId: number): Promise<void> {
    try {
      const appConfig = await appconfigService.get()
      const serviceUrl = appConfig.getServiceUrl(
        ServiceConfig.CART_REMOVE_PRODUCT
      )

      await authAxios
        .post(serviceUrl, {
          Data: {
            Product: {
              OrderProductId: orderProductId
            }
          }
        })
        .then(ServiceHandler.checkSuccess<CartResponse>(CartResponse))
        .catch(ServiceHandler.catchError())

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }

  public async removeProductList (orderProductIdList: number[]): Promise<void> {
    try {
      const appConfig = await appconfigService.get()
      const serviceUrl = appConfig.getServiceUrl(
        ServiceConfig.CART_REMOVE_PRODUCT_LIST
      )

      await authAxios
        .post(serviceUrl, {
          Data: {
            ProductList: orderProductIdList.map((orderProductId) => {
              return {
                OrderProductId: orderProductId
              }
            })
          }
        })
        .then(ServiceHandler.checkSuccess<CartResponse>(CartResponse))
        .catch(ServiceHandler.catchError())

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }

  public async updateProductQuantity (
    orderProductId: number,
    quantity: number
  ): Promise<void> {
    try {
      const appConfig = await appconfigService.get()
      const serviceUrl = appConfig.getServiceUrl(
        ServiceConfig.CART_UPDATE_PRODUCT
      )

      await authAxios
        .post(serviceUrl, {
          Data: {
            OrderProduct: {
              OrderProductId: orderProductId,
              ProductQuantity: quantity
            }
          }
        })
        .then(ServiceHandler.checkSuccess<CartResponse>(CartResponse))
        .catch(ServiceHandler.catchError())

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }

  public async setCartInfo (config: CartConfigInfo): Promise<CartResponse> {
    try {
      const appConfig = await appconfigService.get()
      const serviceUrl = appConfig.getServiceUrl(ServiceConfig.CART_SET_INFO)

      const resp = await authAxios
        .post(serviceUrl, {
          Data: {
            Platform: 'web',
            BranchId: config.BranchId,
            OrderIsPickUp: config.OrderIsPickUp,
            OrderIsDineIn: config.OrderIsDineIn,
            OrderIsKiosk: config.OrderIsKiosk
          }
        })
        .then(ServiceHandler.checkSuccess<CartResponse>(CartResponse))
        .catch(ServiceHandler.catchError())

      return resp
    } catch (err) {
      return Promise.reject(err)
    }
  }

  public async getCart (config: CartConfigInfo): Promise<CartResponse> {
    try {
      const appConfig = await appconfigService.get()
      const serviceUrl = appConfig.getServiceUrl(ServiceConfig.CART_GET)

      const resp = await authAxios
        .post(serviceUrl, {
          Data: {
            Platform: 'web',
            BranchId: config.BranchId,
            OrderIsPickUp: config.OrderIsPickUp,
            OrderIsDineIn: config.OrderIsDineIn,
            OrderIsKiosk: config.OrderIsKiosk
          }
        })
        .then(ServiceHandler.checkSuccess<CartResponse>(CartResponse))
        .catch(ServiceHandler.catchError())

      return resp
    } catch (err) {
      return Promise.reject(err)
    }
  }

  public async validatePlace (order: PlaceInputRequest): Promise<boolean> {
    try {
      const appConfig = await appconfigService.get()
      const config = store.getters.currentConfig as Nullable<CartConfigInfo>
      const serviceUrl = appConfig.getServiceUrl(
        ServiceConfig.CART_PLACE_VALIDATION
      )

      order.OrderIsKiosk = true
      order.OrderAddressPhone = order.OrderAddressPhone ?? '0000000000'

      const resp = await authAxios
        .post(serviceUrl, {
          Data: {
            Order: order,
            Client: {
              ClientFirstName: config?.ClientName ?? 'Usuario',
              ClientLastName: config?.ClientName ? '\0' : 'Kiosco',
              ClientEmail: 'no-reply@adomicil.io',
              ClientPhone: order.OrderAddressPhone
            }
          }
        })
        .then(ServiceHandler.checkSuccessEmpty())
        .catch(ServiceHandler.catchError())

      return resp
    } catch (err) {
      return Promise.reject(err)
    }
  }

  public async placeGuest (order: PlaceInputRequest): Promise<PlaceResponse> {
    try {
      // return Promise.reject('Prueba cancelación')
      const appConfig = await appconfigService.get()
      const config = store.getters.currentConfig as Nullable<CartConfigInfo>
      const serviceUrl = appConfig.getServiceUrl(ServiceConfig.CART_PLACE_GUEST)

      order.OrderIsKiosk = true
      order.OrderAddressPhone = order.OrderAddressPhone ?? '0000000000'

      const resp = await authAxios
        .post(serviceUrl, {
          Data: {
            Order: order,
            Client: {
              ClientFirstName: config?.ClientName ?? 'Usuario',
              ClientLastName: config?.ClientName ? '\0' : 'Kiosco',
              ClientEmail: 'no-reply@adomicil.io',
              ClientPhone: order.OrderAddressPhone
            }
          }
        })
        .then(ServiceHandler.checkSuccess<PlaceResponse>(PlaceResponse))
        .catch(ServiceHandler.catchError())

      return resp
    } catch (err) {
      return Promise.reject(err)
    }
  }

  public async sendOrderSms (
    id: number,
    code: string,
    phone: string
  ): Promise<void> {
    try {
      const appConfig = await appconfigService.get()
      const serviceUrl = appConfig.getServiceUrl(ServiceConfig.ORDER_SEND_SMS)

      await authAxios
        .post(serviceUrl, {
          Data: {
            OrderId: id,
            ClientPhone: phone,
            ClientPhoneCode: code
          }
        })
        .then(ServiceHandler.checkSuccessEmpty())
        .catch(ServiceHandler.catchError())
    } catch (err) {
      return Promise.reject(err)
    }
  }

  public async addPendingRefund (
    id: number,
    branchId: number,
    transactionId: string,
    reference: string,
    merchant: string,
    auth: string,
    amount: number
  ): Promise<void> {
    try {
      const appConfig = await appconfigService.get()
      const serviceUrl = appConfig.getServiceUrl(ServiceConfig.ADD_REFUND)

      await authAxios
        .post(serviceUrl, {
          Data: {
            OrderId: id,
            BranchId: branchId,
            OrderPaymentTransactionId: transactionId,
            OrderPaymentTransactionReference: reference,
            OrderPaymentTransactionMerchant: merchant,
            OrderPaymentTransactionAuth: auth,
            OrderPaymentTransactionAmount: amount
          }
        })
        .then(ServiceHandler.checkSuccessEmpty())
        .catch(ServiceHandler.catchError())
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default new CartService()
