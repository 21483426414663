/* eslint-disable prefer-promise-reject-errors */
import { Nullable } from '@/utils/customtypes.util'
import {
  PaymentInputModel,
  PaymentResponseModel,
  RefundInputModel,
  RefundResponseModel
} from '../models/payment.model'
import { PaymentProvider } from '../payment-provider'
import axios from 'axios'
import { getPrinterService } from '@/utils/printer/printer'
import { MitPaymentResponseModel } from '../models/mit/payment-response.model'
import store from '@/store'

interface PinpadMitConfig {
  endpoint: string
}

export class MitUtil implements PaymentProvider {
  config = ((): Nullable<PinpadMitConfig> => {
    try {
      return store.getters.pinpadConfigMitDll
    } catch (err) {
      return null
    }
  })()

  get paymentEndpoint (): string {
    return `${this.config?.endpoint}/api/v1/mit/payments`
  }

  get refundEndpoint (): string {
    return `${this.config?.endpoint}/api/v1/mit/refunds`
  }

  get initOnStart (): boolean {
    return false
  }

  get requiresInteractiveCancel (): boolean {
    return false
  }

  async init (): Promise<object> {
    if (!this.config) {
      return Promise.reject('Falta realizar configuración de pinpad')
    }

    return {}
  }

  executePayment (data: PaymentInputModel): Promise<PaymentResponseModel> {
    return axios
      .post<MitPaymentResponseModel>(this.paymentEndpoint, {
        amount: data.amount,
        tip: data.tip,
        reference: data.reference
      })
      .then((response) => {
        console.log(response)

        const responseData = {
          transactionId: response.data.paymentId,
          reference: response.data.reference,
          authorization: response.data.authorization,
          total: response.data.amount,
          amount: data.amount,
          tip: data.tip,
          cardType: response.data.cardBrand,
          date: new Date().toISOString(),
          ticket: response.data.ticket,
          merchant: response.data.merchant
        }

        if (responseData.ticket) {
          const printerService = getPrinterService()
          printerService
            .getConfig()
            .then((config) => {
              printerService.print(
                config,
                this.verifyVoucher(responseData.ticket ?? '')
              )
            })
            .catch((err) => {
              console.log(err)
              // ignore
            })
        }

        return responseData
      })
      .catch((error) => {
        const errorDetail = error.response?.data
        const message =
          errorDetail?.detail ?? errorDetail?.title ?? error.message

        return Promise.reject(message)
      })
  }

  cancel (data: RefundInputModel): Promise<RefundResponseModel> {
    return axios
      .post<MitPaymentResponseModel>(this.refundEndpoint, {
        paymentId: data.transactionId,
        authorization: data.authorization,
        reference: data.reference,
        amount: data.amount,
        tip: data.tip
      })
      .then((response) => {
        console.log(response)

        return {
          transactionId: response.data.authorization,
          reference: response.data.reference,
          authorization: response.data.authorization,
          amount: response.data.amount,
          cardType: response.data.cardBrand,
          date: new Date().toISOString(),
          ticket: this.verifyVoucher(response.data.ticket ?? '')
        }
      })
      .catch((error) => {
        console.log(error)
        return Promise.reject(error.detail ?? error.title ?? error.message)
      })
  }

  private verifyVoucher (voucher: string) {
    voucher = voucher.replace('@cnb logo_cpagos', '')
    voucher = voucher.replace('@cnn ver_app', '')
    voucher = voucher.replace(/@cnb /g, '')
    voucher = voucher.replace(/@cnn /g, '')
    voucher = voucher.replace(/@br /g, '')
    voucher = voucher.replace(/@lnn /g, '')
    voucher = voucher.replace(/@lsn /g, '')
    voucher = voucher.replace(/@bc /g, '')

    return voucher
  }
}
