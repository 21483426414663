import { Actions } from '@/config/store.config'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import { v4 as uuidv4 } from 'uuid'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/select-type',
    name: 'selectType',
    component: () => import('../views/SelectTypeView.vue')
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import('../views/GroupsView.vue')
  },
  {
    path: '/group/:id/:categoryId?',
    name: 'group',
    component: () => import('../views/SingleGroupView.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('../views/CategoriesView.vue')
  },
  {
    path: '/category/:id',
    name: 'category',
    component: () => import('../views/SingleCategoryView.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/checkout/OrderView.vue')
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () => import('../views/checkout/ConfirmView.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/checkout/InfoView.vue')
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/checkout/PaymentView.vue')
  },
  {
    path: '/refund',
    name: 'refund',
    component: () => import('../views/checkout/RefundView.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/checkout/SuccessView.vue')
  },
  {
    path: '/refund-success',
    name: 'refund-success',
    component: () => import('../views/checkout/RefundSuccessView.vue')
  },
  {
    path: '/thanks-you',
    name: 'thanks-you',
    component: () => import('../views/checkout/ThanksYouView.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/checkout/ErrorView.vue')
  },
  {
    path: '/refund-error',
    name: 'refund-error',
    component: () => import('../views/checkout/RefundErrorView.vue')
  },
  {
    path: '/cash-payment',
    name: 'cash-payment',
    component: () => import('../views/checkout/CashPaymentVue.vue')
  },
  {
    path: '/your-phone',
    name: 'phone-number',
    component: () => import('../views/PhoneNumberView.vue')
  },
  {
    path: '/your-name',
    name: 'client-name',
    component: () => import('../views/ClientNameView.vue')
  },
  {
    path: '/unavailable',
    name: 'unavailable',
    component: () => import('../views/checkout/UnavailableView.vue')
  },
  {
    path: '/test/printer-test-page',
    name: 'test',
    component: () => import('../views/tests/PrinterTestView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (store.getters.isAuthenticated) {
    store.dispatch(Actions.VERIFY_AUTH)
  } else {
    const uuid = uuidv4()
    store.dispatch(Actions.SIGNUP_GUEST, uuid)
  }

  next()
})

export default router
